export default {
  meau: {
    nav: 'ホーム',
    nav1: 'DBCの使用',
    nav1_1: 'GPUクラウドサービスの購入',
    nav1_2: '報酬のためにPOSノードを展開する',
    nav1_3: 'POSノードに投票する',
    nav1_4: '報酬のためにGPUマイナーになる',
    nav1_5: '理事会メンバーになる',
    nav1_6: '理事会候補者に投票する',
    nav1_7: '住民投票に参加する',
    nav2: '学ぶ',
    nav2_1: 'DeepBrainChainとは何ですか？',
    nav2_2: 'GPUとは何ですか？',
    nav2_3: 'DeepBrainChainの発展の歴史',
    nav2_4: 'ホワイトペーパー',
    nav2_5: 'DBC DAO自治の紹介',
    nav2_6: 'チームについて',
    nav3: '開発者',
    nav3_1: '強力なGPUを借りるためのAPI',
    nav3_2: 'クラウドカフェブランドになる方法',
    nav3_3: 'クラウドゲーミングコンソールの代理店になる方法',
    nav3_4: 'AAAゲームをクラウドゲームに変換する方法',
    nav3_5: '理事会の財源に申し込む方法',
    nav4: 'ウォレット',
    nav4_1: 'DBCウォレット',
    nav4_2: 'Polkaウォレット',
    nav4_3: 'ERC20ウォレット',
    nav4_4: 'Mathウォレット',
    nav4_5: 'ImToken',
    nav4_6: 'Trustウォレット',
    nav4_7: 'MetaMask',
    nav5: 'WIKI',
    nav6: 'DBCを購入する',
    nav7: 'コミュニティ',
    nav8: 'DBC Scan'
  },
  home: {
    cont1: {
      text1: 'ようこそ',
      // text2: 'DeepBrain Chain',
      // text3: 'DeepBrain Chainは、無限にスケーラブルな分散型高性能GPUコンピューティングネットワークであり、目標はAI+メタバース時代に世界で最も広く使用されるGPUコンピューティングインフラストラクチャになることです。',
      // text4: 'DeepBrain Chainは2017年に設立され、DeepBrain Chain FoundationとDeepBrain Chain CouncilがDBCの開発を共同で推進しています。',
      // text5: 'DBCブロックチェーンメインネットは2021年5月20日に開始され、DBCのGPUコンピューティングメインネットは2021年11月22日に正式に開始されました。',
      
      text2: 'DeepBrainChain 2.0',
      text2_1: 'DBC = AI + DePIN +GPU',
      text3: 'DeepBrainChain 2.0は世界初のAIパブリックチェーンであり、分散型AIインフラを構築し、EVMスマートコントラクト標準に対応し、分散型AIモデルの運用をサポートします。',
      text4: 'DeepBrainChainは2017年に設立され、DeepBrainChain財団とDeepBrainChain評議会の2つの組織がDBCの発展を共同で推進しています。',
      text5: '2021年にDBC 1.0 GPU分散コンピューティングパワーネットワークが立ち上げられ、2024年8月にDBC 2.0 AIパブリックチェーンテストネットが開始されました。',
    },
    cont2: {
      title1: '華為、DeepBrain Chain、PolygonがメタバースとWeb3連合を共同設立',
      text1: 'ビデオを見る',
      title2: 'DBC GPUコンピューティングメインネットが立ち上がりました',
      text2: 'メインネットGPUコンピューティング情報',
      title3: 'AIコンピューティングネットワーク',
      text3: 'DBCピッチデッキ',

      title4: 'DeepLink パブリックセールは',
      title4_1: '実施中',
      text4: '今すぐ参加'
    },
    // cont3: {
    //   title: 'DBC アプリケーションエコロジー',
    //   text: 'DBCを基にして、誰でも独自のGPUクラウドサービスプラットフォームを構築できます',
    //   title1: 'AIトレーニング',
    //   text1: 'AIトレーニングは、大量のデータとアルゴリズムを使用してニューラルネットワークをトレーニングすることを指します。トレーニングの目的は、予測が可能なモデル、つまりニューラルネットワークの重みとパラメータを得ることです。2024年までに、AIトレーニング用のGPUサーバの市場規模は120億ドルに達し、次の5年間の複合年間成長率は25％になると推定されています。',
    //   btn1_1: 'Haibao Cloud',
    //   btn1_2: 'Hycons Cloud',
    //   title2: 'AI推論',
    //   text2: 'AI推論は、トレーニングされたニューラルネットワークを使用して新しいデータを分析および予測することを指します。推論の目的は、トレーニングされたモデルを使用して新しいデータからさまざまな結論を推論することです。2024年までに、AI推論用のGPUサーバの市場規模は80億ドルに達し、次の5年間の複合年間成長率は35％になると推定されています。',
    //   title3: 'クラウドゲーム',
    //   text3: 'クラウドゲームサービスを利用すると、ゲームはクラウドベースのGPUサーバを介してレンダリングおよび処理され、その後ゲーム画像がプレイヤーのデバイスにストリームされます。クラウドゲームを使用すると、任意のAAAゲームを任意のデバイスで実行できます。クラウドゲーム市場は急速に成長しており、2030年までに市場規模が209.3億ドルに達し、次の5年間の複合年間成長率が45.5％になると推定されています。',
    //   btn3_1: 'DeepLink',
    //   title4: 'ビジュアルレンダリング',
    //   text4: 'ビジュアルレンダリングソリューションは主に映画および3Dアニメーションの分野で使用されます。グローバル市場規模は2023年に723.7百万ドルで、2033年までの複合年間成長率が17.3％で急速に拡大し、35.7億ドルに達すると予測されています。',
    //   btn4_1: 'Hycons Cloud',
    //   title5: 'クラウドカフェ',
    //   text5: 'クラウドカフェは、クラウドコンピューティング技術に基づく新しいタイプのネットカフェサービスです。クラウドカフェでは、ゲームとアプリケーションが実際にはリモートGPUサーバで実行され、その後インターネットを介してリアルタイムにネットカフェのコンピュータにストリーミングされます。ネットカフェのオペレーターは、高性能GPUハードウェアコンピュータへの投資を必要としません。これにより、ハードウェア投資コストが大幅に削減されます。2023年までに、世界中に20万を超えるネットカフェがあり、合計1000万台のコンピュータがありました。クラウドカフェの現在の市場シェアは1％未満であり、次の5年間で年間市場成長率が100％を超えます。',
    //   btn5_1: 'ZestCloud',
    //   btn5_2: 'Tikeren Cloud',
    //   title6: 'ZKマイニング',
    //   text6: 'Filecoin、Aleo、ETH L2 ZKコンピューティングなどは、ゼロ知識証明計算にGPUサーバが必要です'
    // },
    cont3: {
      title: 'DBC 2.0 AI エコシステム展示',
      text: 'どのAIプロジェクトもDBC 2.0に基づいて分散型AIを開発し、独自のトークンを発行し、AIモデルを永久に実行し、停止されることはありません。',
      title1: 'DeepLink',
      text1: 'DeepLinkプロトコルは、超低遅延のゲームレンダリング技術を提供するAIとブロックチェーン技術を組み合わせた分散型AIクラウドゲーミングプロトコルです。',
      btn1_1: 'DeepLink',
      title2: 'DecentralGPT',
      text2: 'DecentralGPTは分散型LLM AI推論ネットワークです。様々なオープンソースの大規模言語モデルをサポートし、安全、プライバシー保護、民主的、透明、オープンソース、そして普遍的にアクセス可能なAGIを構築することに取り組んでいます。',
      btn2_1: 'DecentralGPT',
      title3: 'SuperImage',
      text3: 'SuperImageは、任意のテキスト入力に対してフォトリアリスティックな画像を生成するための複数の潜在テキストから画像への拡散モデルを持ち、驚異的な画像を生成する自律的な自由を育み、数十億人の人々が瞬時に素晴らしいアートを作成できるようにします。',
      btn3_1: 'SuperImage',
      title4: 'DRCpad',
      text4: 'AIノードを取引するための最初の一次市場であり、DBC AIパブリックチェーンのノード取引プラットフォームに焦点を当て、高品質のAIプロジェクトを選別します。',
      btn4_1: 'DRCpad',
      title5: 'Hycons',
      text5: 'HYCONS CLOUDのビジネスパートナー：AI、自動運転、バイオ医薬、クラウドゲーム、メタバース、クラウドXR、AR、VRビジュアルレンダリング、気候シミュレーション。GPUが必要な方は誰でもHYCONSパートナーになることができます。',
      btn5_1: 'Hycons Cloud',
      title6: 'DBCメインネットブロックエクスプローラー',
      text6: 'メインネットのブロックチェーンエクスプローラーは、ウォレットホルダーの数、メインネットウォレットの残高、およびDBCメインネットウォレットの転送情報を確認できます。',
      btn6_1: 'DBCメインネットブロックエクスプローラー',
      title7: 'DBCSCAN',
      text7: 'DBCSCANはDBC AIパブリックチェーンのEVMエクスプローラーです。現在オンラインで、テストネットの運用をサポートし、トランザクションの照会やスマートコントラクトのデプロイが可能です。',
      btn7_1: 'DBCSCAN',
      title8: 'DBCWallet',
      text8: 'DBCWalletは、DBC財団が維持するフル機能のウォレットで、POS投票報酬、POSステーキング、評議会選挙と投票、財務提案の投票、国民投票をサポートします。',
      btn8_1: 'DBCWallet',
    },
    cont4: {
      title: 'DBC 今日の状況',
      text: '最新のネットワーク統計',
      text1: 'GPU 数',
      text2: 'GPU レンタル率',
      text3: 'GPU でステーキングされた DBC の量',
      text4: 'GPU レンタルで消費される DBC の量',
      text5: '発行された DBC の総量',
      text6: 'DBC 保有者アドレスの数',
      text7: 'ステーキングのためにステーキングされた DBC の量',
      text8: '理事会 DAO の財源'
    },
    cont5: {
      title: 'なぜAI開発者は私たちを選ぶのか？',
      text: '誰でもDBCをベースに自分のGPUクラウドサービスプラットフォームを構築できます。',
      block1: {
        title: 'GPU無料使用',
        text: 'AI開発者は自分のトークンを発行するだけでGPUを無料で使用できます。',
      },
      block2: {
        title: '開発期間の短縮',
        text: 'AI開発者が分散型AIの開発期間を3年から3ヶ月に短縮し、開発コストを大幅に削減できます。',
      },
      block3: {
        title: 'スマートコントラクトのデプロイ',
        text: 'AI開発者が自分のスマートコントラクトをデプロイし、自分のトークンを発行することをサポートします。',
      },
      block4: {
        title: '高TPS、低Gas',
        text: '6秒ごとにブロック生成、1000以上のTPSをサポートし、ガス料金は0.001 DBCまで低くなります。',
      },
      block5: {
        title: 'オープンソースかつ無許可',
        text: 'どのクラウドプラットフォームでもDBCをベースに独自のGPUクラウドサービスプラットフォームを構築できます。',
        text1: '特定の分野の顧客に許可なくサービスを提供します。',
      },
      block6: {
        title: '分散型AIモデルのデプロイ',
        text: 'AIモデルの分散型デプロイをサポートし、モデルが停止することなく永続的に稼働します。ユーザーの信頼と完全に透明なメカニズムをもたらす真の分散型モデル運用を実現します。',
      }
    },
    cont6: {
      title: 'DBCトークンの価値',
      block1: {
        text: 'DBCトークンの総数は100億であり、固定供給量であり、増加しません。総供給量は約100年間で発行されます。',
      },
      block2: {
        text: 'DBCトークンはDBCネットワークで唯一のトークンです。',
        text1: 'ユーザーがGPUをレンタルするたびに、取引所やその他の手段からDBCトークンを購入し、GPUの利用権を取得するためにDeepBrainChainブロックチェーンネットワークに一定量のDBCを支払う必要があります。',
      },
      block3: {
        text: 'DBCは通貨の価値が減少するモデルに従います。DeepBrainChainネットワーク全体のGPUの総数が5,000を下回ると、ユーザーのレンタル料金の30%が破壊されます。5,000を超えると、70%が破壊され、10,000を超えると100%が破壊されます。',
        text1: 'ユーザーが支払ったDBCは取引所やその他の手段から購入する必要があります。ユーザーがGPUをレンタルするたびに、市場でのDBCの流通量が減少します。',
      },
      block4: {
        text: 'DBC POSスーパーノードは、ブロック報酬のためにDBCをステークする必要があります。現在、全ネットワークでステーキングされているDBCの総額は{stakeNum}であり、発行されたDBCの総数の{ratio}%を占めています。',
      },
      block5: {
        text: 'マイナーはGPUを提供するためにDBCをステークする必要があります。各カードには、100,000 DBCまたは最大で800ドル相当のDBCのステークが必要です。つまり、DeepBrainChainネットワーク内のGPUが多ければ多いほど、ステークされるDBCが多くなります。現在、全ネットワークでGPUマイナーがステーキングしているDBCの総額は{stakeNum}であり、発行されたDBCの総数の{ratio}%を占めています。',
      },
      block6: {
        text: 'DBCトークンはDeepBrainChainのガバナンストークンです。',
        text1: '理事会DAOは、4か月ごとにすべての候補者から21人の理事会メンバーを選出します。',
        text2: 'トップ21の中で最も多くのDBCトークンの投票を受けた候補者が選ばれます。',
        text3: '各DBCトークンは1票に相当します。',
        text4: '理事会DAOは、DeepBrainChainの生態系開発を支援するために国庫資金を共同で管理します。',
      }
    },
    cont7: {
      title: 'DBCの取引場所',
      text1: 'DBC精度：15、トークンシンボル：DBC、ERC20 DBC契約アドレス：',
      text2: '0x1F735f84b07cc20E9aC471C291a87b5A2428d518'
    },
    cont8: {
      title: 'トークン経済モデル',
      text1: '現在のDBC GPUコンピューティングパワーリワードの毎日の発行枚数：1,095,900枚、DBCメインネットPOSノードの毎日のアウトプット：273,900枚',
      table: {
        tr1: '使用法',
        tr2: 'カテゴリー',
        tr3: '小計',
        tr4: '数量（10億）',
        tr5: '流通量（10億）',
        tr6: '解放待ち（10億）',
        tr7: '備考',
        tbody1: {
          text1: '初期販売',
          text2: '専門投資家やAI企業向けにDBCエコシステムサービスの利用権を販売',
        },
        tbody2: {
          text: '財団とエコシステム',
          text1: '財団',
          text2: '2018年から2021年までの10年間で解除された10億枚（年間25億枚）と、残りの15年で解除された10億枚（年間0.49億枚）',
        },
        tbody3: {
          text1: '理事会',
          text2: '理事会全体はコミュニティによって選出され、DBCは完全に理事会によって管理され、15年かけてロックが解除され、毎年0.51億枚の解除が行われます。 2022年1月から開始',
        },
        tbody4: {
          text1: 'チーム',
          text2: '市場に上場した後、10％が1か月間ロックされ、残りの10％は10年間毎年解除され、2018年から開始されます。',
        },
        tbody5: {
          text1: 'メインネット前の算力インセンティブ',
          text2: 'メインネットが稼働する前にマイナーがGPUをDBCの計算能力ネットワークに参加するためのインセンティブとして使用されます',
        },
        tbody6: {
          text: 'ノード報酬',
          text1: 'スーパーノード',
          text2: 'スーパーノードはブロックの計算で報酬を受け取ります。最初の3年間は、毎年10億枚、次の5年間は毎年5億枚、その後、5年ごとに報酬が半分になります。報酬は2021年5月から始まります',
        },
        tbody7: {
          text1: '計算力ノード',
          text2: '計算力ノードは計算力を貢献することでオンライン報酬を受け取ります。最初の3年間は、毎年40億枚、次の5年間は毎年20億枚、その後、5年ごとに報酬が半分になります。報酬は2021年8月から始まります',
        },
        tbody8: {
          text1: '合計',
        }
      }
    },
    cont9: {
      title: '投資機関とパートナー'
    },
    cont10: {
      title: '開発履歴とロードマップ'
    },
    cont12: {
      title: 'メディア報道'
    },
    cont13: {
      title: 'コミュニティ活動'
      } 
  },
  buyDBC: {
    title: 'DBCの購入',
    buy: '購入',
    see: '見る',
    block1: {
      title: '中央集権取引所でDBCを購入'
    },
    block2: {
      title: '分散型取引所でDBCを購入'
    },
    block3: {
      title: 'スワッププラットフォームでDBCを購入',
      text: 'ウォレットを介して直接スワップできます'
    },
    block4: {
      title: 'サードパーティクラウドプラットフォームでDBCを購入'
    }
  },
  buyGpuServer: {
    title: 'DBCトークンを使用してGPUクラウドサービスを購入する',
    text1: 'DeepBrain Chainは、無限に拡張可能な分散型の高性能GPUコンピューティングネットワークであり、世界中のプロバイダーがGPUサーバーをDBCネットワークに追加し、10万ドルまたは800米ドル相当のDBCを賭けてGPUサーバーの安定性と利用可能性を確保します。サードパーティーの開発者は、DBCに基づいて独自のクラウドプラットフォームを開発してユーザーにサービスを提供できます。開発者はGPUサーバーを購入する必要はなく、ユーザーにサービスを提供するだけです。すべてのGPUサーバーは、DBCネットワークから自動的にアクセスできます。',
    text2: 'ユーザーは、クラウドプラットフォームでDBCを使用してGPUをレンタルしたり、法定通貨を使用してGPUをレンタルしたりできます。法定通貨を使用してGPUをレンタルする場合、クラウドプラットフォームは法定通貨をDBCに自動変換してDeepBrain Chain内のGPUをレンタルします。',
    text3: 'DBCエコロジカルアプリケーションサービス'
  },
  getReward: {
    title: 'POSブロック生産ノードを展開して報酬を得る',
    text: 'DBCはPOSコンセンサスメカニズムを採用しています。現在、合計41のコンセンサスブロック生産ノードがあります。 24時間ごとに、すべてのノードから41個のノードがブロック生産ノードとして選出されます。 選択されたノードは、24時間ごとに272,500 DBCの報酬を生成できます。 選挙のルール：自己のステークされたDBCとそれに投票されたDBCで上位41位のノード。',
    seedetails: '詳細を見る',
    cont: {
      title: 'DBC検証ノードになる'
    },
    procedure1: {
      title1: 'マシンの構成',
      text1: 'メモリ：8GB',
      text2: 'CPU：2コア',
      text3: 'ハードディスク：100GB',
    },
    procedure2: {
      title1: '資金口座の生成（すでに資金口座がある場合はスキップできます）',
      text1: '参考：',
      text2: 'アカウントの生成方法'
    },
    procedure3: {
      title1: 'dbc-chainのバイナリを取得',
      text1: '方法1：事前コンパイル済みバージョンを使用する',
      text2: '正常に実行できない場合は、自分でコンパイルするために方法2を使用してください：',
      text3: '方法2：ソースコードからコンパイルする',
      text4: '# 依存関係のインストール',
      text5: '# dbc-chainのコンパイル'
    },
    procedure4: {
      title1: '同期ノードを実行する',
      text1: 'ソースコードからコンパイルしている場合、実行可能ファイルのパスは次のとおりです：',
      text2: '同期が完了したら、Control + Cでプログラムを閉じます。ターゲットとベストの比較に基づいて同期が完了したかどうかを判断できます。ターゲットとベストの差が小さい場合（例えば100以内）、同期が完了したと見なすことができます。',
      text3: 'パラメータの説明：',
      text4: '--base-path：ブロックチェーンがデータを格納するディレクトリを指定します。指定しない場合、デフォルトのパスが使用されます。ディレクトリが存在しない場合、自動的に作成されます。ディレクトリにすでにブロックチェーンデータが含まれている場合、エラーが発生します。この場合、異なるディレクトリを選択するか、このディレクトリの内容をクリアする必要があります。',
      text5: '--pruning=archive：アーカイブモードでブロックチェーンを起動します。',
      text6: '--bootnodes：ブートノードのアドレスを指定します。',
    },
    procedure5: {
      title1: '検証人としてノードを実行する',
      text1: 'ステップ3でノードデータの同期が完了した後、同期コマンドを終了します。次に、ノードを検証者として実行します：',
      text2: 'ソースコードからコンパイルしている場合、実行可能ファイルのパスは次のとおりです：',
      text3: 'ここでの --name YourNodeName は、ノードの名前を設定するためのものです。ノードに一意で簡単に識別できる名前を付けることができます。他の人は、ネットワーク上でそれを見ることができます。',
      text4: 'rotateKeyを生成する',
      text5: '検証ノードを実行しているマシンで次のコマンドを実行します。'
    },
    procedure6: {
      title1: 'ボンド金額の設定',
      text1: 'を開く',
      text2: 'あなたの残高が表示されます：',
      text3: 'ネットワーク > ステーキング > アカウント操作 > スタッシュアカウントに移動します',
      text4: 'ボンド金額を設定します（ボンドされた金額以外にもトランザクションを送信するための十分な残高があることを確認してください）',
      text5: '説明：',
      text6: 'スタッシュアカウント：あなたの資金アカウント。ここでは45 DBCをボンドしています。アカウントにはこの金額以上の残高があることを確認してください',
      text7: 'コントローラーアカウント：このアカウントにも少額のDBCが必要です。バリデータを開始および停止するトランザクションを送信するためです',
      text8: 'ボンドされた値：ボンド/ステークするDBCの量。アカウントのすべての残高をボンドする必要はなく、後でボンドされた金額を増やすことができます。',
      text9: '支払先：バリデータが得た報酬はこのアカウントに送金されます。このアカウントは任意のアカウントに設定できます。ここで、',
      text10: 'スタッシュアカウント（ステークされた金額を増やす）：利益はスタッシュアカウントに支払われ、ステークされます',
      text11: 'スタッシュアカウント（ステークされた金額を増やさない）：利益はスタッシュアカウントにステークなしで支払われます'
    }
  },
  voteReward: {
    title: 'POSノードに投票して報酬を獲得する',
    text: '各スーパーノードは、1日あたり約6700 DBCの報酬を受け取ります。投票ノードがスーパーノードとして選択された場合、投票者も報酬を受け取ることができます。投票者の報酬額は、（1-投票者が設定した手数料率）私の投票額/総投票額 27.25万で計算されます。例えば、私が投票したスーパーノードの総投票額が2000万DBCで、私が100万DBCで投票し、ノードの手数料率が10％の場合、私の1日の報酬は90％100万/2000万6700 = 301 DBCです。およそ、年間で約11万DBCの報酬を受け取ることができます。',
    seedetails: '詳細を見る',
    cont: {
      title: 'DBCノミネート者になる'
    },
    procedure1: {
      title1: '資金口座を作成する（すでに持っている場合はスキップ）',
      text1: '参考：',
      text2: 'アカウントの作成方法'
    },
    procedure2: {
      title1: 'いくつかのDBCを取得する',
      text1: '開く',
      text2: 'あなたの残高が表示されます：'
    },
    procedure3: {
      title1: '債権額を設定する',
      text1: 'ネットワーク>ステーキング>アカウントアクション>スタッシュアカウントに移動し、',
      text2: 'ポップアップでスタッシュアカウント、コントローラーアカウントを選択し、債権額を入力し、次にクリックします。',
    }
  },
  gpuMiner:{
    title: 'GPUマイナーになって報酬を獲得する',
    text: 'DBCエコシステムでは、マイナーはGPUサーバーを提供することで重要な役割を果たします。これらのサーバーはDBCネットワークの基盤です。現在、GPUプロバイダーには1,090,000 DBCの報酬が毎日支払われています。マイナーは報酬と賃貸収入の両方を受け取ります。 GPUが5,000枚未満のマイナーは賃貸収入の70％を受け取り、5,000枚以上10,000枚未満のマイナーはユーザーからの賃貸収入の30％を受け取ります。 10,000枚以上のGPUを持つマイナーは賃貸収入を受け取りませんが、マシンが貸し出されるたびに報酬が30％増加します。 各GPUは、100,000 DBCまたは$ 800 USDのDBC相当額をステーキングする必要があります。 いずれかが低いです。',
    seedetails: '詳細なルールを表示',
    cont: {
      title: 'GPUマシンの月間収益計算式',
      text: '月間収益の計算式：（マシンのハッシュレート/総ネットワークハッシュレート）* 1,095,890 * DBC価格 * 地域係数 * 大規模モデル係数 * 1.3（貸し出しハッシュレートの30％増加）* 30 +（マシンハッシュレート地域係数大規模モデル係数0.50870％）',
      text1: 'マシンモデル：',
      text2: 'グラフィックカードの数：',
      text3: 'メモリユニットの数：',
      text4: '地域：',
      text5: '選択してください',
      text6: '現在の月間収益：',
      text7: 'USD相当DBC = ',
      text8: 'チェーン上の報酬収益：',
      text9: 'DBC（相当',
      text10: 'USD、現在のDBC価格：',
      text11: 'USD）+賃貸収入：',
      text12: 'USD相当DBC'
    },
    country: {
      country1: '中国',
      country2: '北米',
      country3: '南米',
      country4: 'ヨーロッパ',
      country5: '中東',
      country6: '韓国',
      country7: '日本',
      country8: '台湾',
      country9: '香港とマカオ',
      country10: 'マレーシア',
      country11: 'インドネシア',
      country12: 'シンガポール',
      country13: 'ベトナム'
    }
  },
  applyCouncil: {
    title: '評議会メンバーになる申請',
    text: 'DBC評議会はDBC DAO組織であり、現在、DBCトレジャリーに800億以上のDBCトークンを管理しており、毎月425万DBCがトレジャリーに入り、2036年まで続きます。 評議会は21人のメンバーで構成され、4か月ごとに選挙が行われます。投票数上位21人の候補者が選ばれます。',
    text1: '評議会メンバーとして、国庫資金の提案に投票できます。どの提案も21人の評議会メンバーのうち少なくとも13人の承認が必要です。投票数が13を超えない場合、首席評議会メンバーは投票しなかったメンバーを代表して投票できます。',
    text2: '評議会メンバーであることは、コミュニティや他の評議会メンバーから信頼を得る可能性が高いです。評議会メンバーが国庫資金提案を行った場合、承認される可能性が高くなります。',
    text3: 'どのコミュニティメンバーでも資金申請提案を起こすことができます。ただし、提案が投票プロセスに進むには、21人の評議会メンバーのうちの1人から提出される必要があります。',
    seedetails1: '評議会会議討論グループ',
    seedetails2: '評議会情報を表示',
    cont: {
      title: '評議会選挙プロセス'
    },
    procedure1: {
      title1: 'PolkadotベースのDBCメインネットウォレット',
      text1: 'まず、PolkadotベースのDBCメインネットウォレットを持っている必要があります。ウォレットを作成した後、DBCメインネットアドレスを開きます。',
      text2: '「ガバナンス」セクションを選択します。',
      text3: 'ドロップダウンメニューから、「議会」を選択します。',
      text3_1: '内部では、現在の議会選挙の状況の概要が表示されます。',
      text4: '「シート」- 現在の選出された評議会メンバー。',
      text5: '「ランナーアップ」- 一時的に刷り込まれた候補者（準優勝者）。',
      text6: '「候補者」- 提出されたが、一時的にトップ20に入らなかった応募者。',
      text7: 'ページの右上には、「投票」と「候補者を提出」のボタンがあります。',
      text8: '評議会メンバーになりたい場合は、「候補者を提出」に移動します。投票のみを希望する場合は、「投票」に移動します。'
    },
    procedure2: {
      title1: '次に、「投票」を見てみましょう',
      text1: '入場すると、アカウント残高が表示されます。',
      text2: 'お好みの候補者を選択し、「投票値」フィールドに投票数を入力します。',
      text3: '1 DBCは1票に相当します。1 DBC未満の投票は成功しません。',
      text4: '確認後、署名と送信、確認のためのパスワードを入力してください。'
    }
  },
  councilVoting: {
    title: '理事会候補者への投票',
    text: 'DBC理事会はDBCトレジャリーを管理するDAO組織です。理事会メンバーはDBCのエコシステムの発展に非常に重要な役割を果たします。すべてのDBC保有者は理事会投票に注意を払い、自分が支持する理事会候補者に神聖な一票を投じるべきです。総投票数で上位21位にランクインした候補者のみが理事に選出されます。',
    seedetails1: '理事会会議討論グループ',
    seedetails2: '理事会情報を表示',
    cont: {
      title: '投票方法'
    },
    procedure1: {
      title1: '理事会投票ページを開く',
      text1: '理事会投票ページを開きます。',
      text2: '投票をクリック（最初にウォレットにログイン）',
      text3: '投票値を設定する',
      text4: '投票値を設定し、理事会候補者をクリックすると、候補者が右のボックスに配置されます。複数の候補者を選択する場合、票は均等に配分されます。最後に、投票をクリックします。注意：投票後、投票コインはステークされ、転送できなくなります。転送操作を行うにはステーク解除が必要です。新しい理事会選挙終了の前日に投票することをお勧めします。コインのステーキング時間が最短になります。',
      text5: '以下の図の赤いボックスは、新しい理事会選挙の終了までの残り時間を示しています。',
      text6: '投票をキャンセルする',
      text7: '投票コインのステークを解除します。いつでも投票をキャンセルできます。[すべてキャンセル]ボタンをクリックすると、ステークがキャンセルされます。ただし、投票をキャンセルした後は、投票がキャンセルされます。新しい理事会選挙の終了後に投票をキャンセルすることをお勧めします。'
    }
  },
  referenda: {
    title: '公式投票に参加する',
    text: 'DBCブロックチェーンネットワークの任意の技術アップグレードやパラメータの変更には、公式投票を通じて承認する必要があります。公式投票はDBCコミュニティガバナンスの重要な部分です。すべてのDBC保有者は、DBCの技術的および製品の開発を追跡し、公式投票に参加し、さらに公式投票の提案を立ち上げることが推奨されています。',
    seedetails: '一般提案を参照する',
    cont: {
      title: 'DBCメインネットの投票に参加する方法'
    },
    procedure1: {
      title1: '【ガバナンス】-【民主権利】インターフェースの紹介',
      text1: '提案 — 一般からの提案。各開始期の終わり（現在は28日ごと）、最も支持されている提案（支持は合計dbc額で表されます）が一般投票に移行します。',
      text2: '外部 — 理事会からの提案。',
      text3: '開始期間 — 新しい公式投票が開始される期間で、現在は28日間です。'
    }
  },
  learnDBC: {
    title1: 'What is',
    title2: 'DeepBrain Chain?',
    text1: 'DeepBrain Chain（ディープブレインチェーン）は、無限にスケーリングできる分散型の高性能GPUコンピューティングネットワークです。その目標は、AI+メタバース時代における世界で最も広く使用されるGPUコンピューティングインフラストラクチャーになることです。',
    text2: 'DeepBrain Chainは2017年に設立され、DeepBrain Chain FoundationとDeepBrain Chain Councilが共同でDBCの開発を推進しました。',
    text3: 'DBCブロックチェーンメインネットは2021年5月20日に開始され、DBC GPUコンピューティングメインネットは2021年11月22日に正式に稼働しました。',
    text4: 'DBCは、世界中のGPUプロバイダーを集めて、ブロックチェーンの報酬メカニズムを通じてGPUサーバーをDBCネットワークに提供します。その後、DBCの分散型ネットワーク技術を使用して、ユーザーにGPUの使用を提供します。ただし、DBCは直接ユーザーに向けられておらず、DBCのアプリケーションエコシステムを通じてユーザーにサービスを提供します。現在、DBCは、AIトレーニング、AI推論、クラウドゲーミング、クラウドカフェ、ZKマイニングなどのエコシステムアプリケーションをユーザーに提供しています。',
    text5: 'エコシステムアプリケーションが多いほど、エコシステムアプリケーション自体のGPUへの需要が増します。毎日のDBCトランザクションの使用量が多いほど、破壊されるDBCも多くなり、DBCの価値も高くなります。',
    text6: 'たとえば、クラウドカフェアプリケーションを例に取ると：クラウドカフェユーザーはGPUを使用するために取引市場でコインを購入する必要があります。追加のGPUを使用するごとに、取引市場で購入したコインの30%が破壊されます。クラウドカフェが1000店ある場合、それぞれのカフェに100台のマシンがあり、それぞれのマシンが1日10時間使用され、1時間あたり0.1米ドル支払われます。そのうち30%が破壊されます。月に90万米ドル相当のコインが破壊されます。 0.002 USDTのコイン価格に基づいて、1か月で4億以上のコインが破壊される必要があります。同時に、1000のクラウドカフェをサポートするためには7万台のマシンが必要で、さらに70億のコインがステークされる必要があります。'
  },
  whatisGPU: {
    title1: 'What is',
    title2: 'GPU？',
    text1: 'GPU（Graphics Processing Unit）は、グラフィックスやビデオ関連のタスクを処理するために特化した計算ユニットです。CPU（中央処理ユニット）とは異なり、GPUは大量のデータを並列処理するために特別に設計されています。',
    block1: {
      title: '高度な並列性能',
      text: 'GPUは数百から数千の小さなコアで構成されており、大量のデータを同時に処理できます。たとえば、3Dグラフィックスをレンダリングする場合、各コアは独立してピクセルや頂点を処理でき、処理速度を大幅に向上させることができます。'
    },
    block2: {
      title: 'グラフィックスの最適化',
      text: 'GPUはもともとグラフィックスのレンダリングを高速化するために設計されており、テクスチャマッピングやライティング計算などの画像やビデオ関連のタスクを効率的に処理できます。'
    },
    block3: {
      title: '幅広い応用範囲',
      text: 'GPUはもともとゲームやプロフェッショナルなグラフィックスデザイン向けに設計されていましたが、現在では人工知能や機械学習など、多くの他の分野でも重要な役割を果たしています。'
    },
    title3: 'ゲームと人工知能',
    title4: 'なぜGPUが必要なのか？',
    text2: 'GPUの高度な並列処理能力により、グラフィックス密度の高いタスクや大規模データ処理タスクを優れた性能で処理できるため、ゲームや人工知能の分野で欠かせない存在となっています。',
    text3: '現在、GPUチップリーダーであるNVIDIAの時価総額は1兆ドルを超え、CPUチップリーダーであるIntelの6倍です。これは、GPUの需要がCPUの需要を大幅に上回っていることを示しています。',
    block4: {
      title: 'ゲーム',
      text: 'ゲームや現代のゲームには、複雑な3Dグラフィックスや物理シミュレーションが含まれることが一般的です。これらのタスクには大規模な並列処理が必要であり、GPUの強力なグラフィックス処理能力が非常に適しています。GPUを使用すると、よりスムーズなゲーム体験やより高いグラフィック品質が実現できます。'
    },
    block5: {
      title: '人工知能と機械学習',
      text: '人工知能の分野、特にディープラーニングでは、大量のデータの処理や複雑な数学的計算が必要です。これらの計算タスクは通常、並列化できるため、GPUの高度な並列性能に非常に適しています。GPUを使用すると、モデルのトレーニングや推論の速度を大幅に高速化できます。'
    }
  },
  historyDBC: {
    title: 'DeepBrainChainの発展史',
    title1: '2017年',
    text1_1: 'DeepBrainChainプロジェクトが立ち上げられ、目標、ビジョン、技術アーキテクチャの方向性が設定されました',
    text1_2: '資金調達の完了',
    title2: '2018年',
    text2_1: 'Huobi取引所でDBCトークンが上場',
    text2_2: 'DBCコンピューティングパワーネットワークが開始され、GitHubでコードがオープンソース化されました',
    title3: '2020年',
    text3_1: 'DBChainのグローバルAI開発者ユーザーが1万人を超え、世界中の500以上のAI関連大学や研究所にサービスを提供しています',
    title4: '2021年',
    text4_1: 'DeepBrainChainノードメインネットが正式に開始',
    text4_2: 'DeepBrainChainメインネットブラウザがローンチ',
    text4_3: 'DeepBrainChainに基づくクラウドプラットフォームが50を超えました',
    text4_4: 'DeepBrainChain GPUコンピューティングパワーメインネットが公開テストを開始',
    text4_5: '公開テストネットワークのGPUコンピューティングパワーの総数が1200枚を超え、レンタル率が98％を超えました',
    text4_6: 'DeepBrainChain GPUコンピューティングパワーメインネットが正式に開始',
    title5: '2022年',
    text5_1: 'Galaxian競技会のGPU数が1500に達する',
    text5_2: 'dbcをベースにしたhaibaogpuクラウドプラットフォームが開始され、AIユーザー向けにGPUサービスを提供しています',
    text5_3: '韓国の3つのプール - DBC KOREA HILLSTONE、DBC KOREA Ai-Factory、およびGines Korea Center-1がDBCネットワークに参加',
    text5_4: 'dbcをベースにした韓国のhyconsクラウドプラットフォームが開始され、AIおよびクラウドゲーミングクライアント向けにGPUサービスを提供',
    text5_5: 'dbcをベースにしたDeepLinkクラウドゲームプラットフォームが開始され、リモートコントロールツールとしてのクラウドゲームプラットフォームとして機能',
    title6: '2023年',
    text6_1: 'Huawei、DeepBrainChain、Polygonが協力してメタバースおよびWeb3アライアンスを設立',
    text6_2: 'DeepBrainChainとDeepLink技術を活用した世界初のZestCloudクラウドサイバーカフェが韓国ソウルで試験運用を開始',
    text6_3: 'DeepBrainChainとDeepLink技術を活用した世界初のクラウドゲーミングマシンTIKERENが日本東京で発表、共同プレゼンテーションが行われました',
    title7: '2024年',
    text7_1: 'Q1',
    text7_2: '1. GPU短期レンタルモードの開発',
    text7_3: '2. メインネットでの新機能のローンチ',
    text7_4: 'Q2',
    text7_5: '1. スマートコントラクト機能のサポートの開発',
    text7_6: '2. GPU短期レンタルモードの強化',
    text7_7: '3. ゲームFiゲームのクラウド化へのサポート',
    text7_8: 'Q3',
    text7_9: '1. DBCをベースにした分散型AIGCプロジェクトのスマートコントラクト開発のサポート',
    text7_10: '2. DBC GPUを使用した分散型AIGCプロジェクトのマイニングのサポート',
    text7_11: '3. スマートコントラクト機能開発テストの完了',
    text7_12: 'Q4',
    text7_13: '1. DBC GPUを使用した3A GameFiのマイニングのサポート',
    text7_14: '2. DBCエコシステム内でのDBCトークンを使用したトークン取引をサポートするDBCSwap機能の開発',
  },
  introDBC: {
    title: 'DBC DAOガバナンスの紹介',
    text1: '2021年にDBCメインネットが開始されたことで、現在DBCの発展を共同で推進している2つの組織があります。それはDBC財団とDBC理事会です。',
    text2: 'DBC財団はDBCの創設組織であり、主にDBCの技術開発とグローバル展開を担当しています。DeepBrainChain財団とDeepBrainChain理事会は、完全に独立した2つの組織であり、いかなる所属関係もなく、DeepBrainChainエコシステムを共同でサービスし、DeepBrainChainの発展を促進しています。',
    cont2: {
      block1: {
        title: 'DBC理事会',
        text1: 'コミュニティによって選出されたDAO組織で、主に国庫資金の管理、DBCエコシステムアプリケーションのサポート、DBCコミュニティの発展、DBCのガバナンスを担当しています。',
        text2: '現在、DBC国庫には総額8億以上のDBCトークンが管理されており、毎月425万DBCが国庫に入り、2036年まで続きます。',
        text3: '理事会は合計21名のメンバーで構成され、4か月ごとに選出されます。上位21人の候補者が選ばれ。',
      },
      block2: {
        title: '理事会メンバーになる',
        text1: '理事会メンバーになることで、国庫資金提案に投票することができます。どの提案も21人の理事のうち13人の承認が必要です。投票数が13未満の場合、最高責任者は投票しない理事を代表して投票することができます。',
        text2: '理事会メンバーになることで、コミュニティや他の理事の信頼を得る可能性が高まります。理事自身が国庫資金提案を申請する場合、承認される可能性が高くなります。',
        text3: 'どのコミュニティメンバーも資金申請提案を開始できますが、提案が投票プロセスに入るには、21人の理事のうち1人が提出する必要があります。',
        btn: '詳細を見る'
      }
    },
    cont3: {
      title: 'DBCの財務資金の使用目的',
      text1: 'インフラの展開',
      text1_2: 'および継続的な運用',
      text2: 'ネットワークセキュリティ運用',
      text2_1: 'モニタリングサービス、継続的な監査',
      text3: 'エコシステムのサポート',
      text3_1: 'および友好的なチェーンとの協力',
      text4: 'マーケティング活動',
      text4_1: '広告、支払い、提携',
      text5: 'コミュニティおよびアウトリーチイベント',
      text5_1: 'ミートアップ、ピザパーティー、ハッカースペース',
      text6: 'インフラの展開と継続的な運用',
      text6_1: 'ウォレットおよびウォレットの統合、クライアントおよびクライアントのアップグレード',
      text7: '取引所のリストアップ',
      text8: 'その他...'
    },  
    cont4: {
      title: '今回の理事会の上位三人のDAOメンバー',
      job: '最高理事',
      reward: '投票数:'
    },
    cont5: {
      title: '新しい理事選挙までのカウントダウン',
      text1: '提案',
      text2: '進行中',
      text3: '承認済み',
      text3_1: '否認されました',
      text4: '申請額',
      text5: '申請者：',
      text6: '終了時間',
      text7: '時間',
      text8: '経過した時間',
      text9: '賛成',
      text10: '反対',
      text11: '日'
    },
    seemore: '続きを見る'
  },
  aboutTeam: {
    title: 'チームについて',
    lead: 'HE YONG',
    job: '創設者',
    intro : {
      text1: "DeepBrainChainとDeepLinkの創設者、何永、連続起業家",
      text2: "AIの経験14年、ブロックチェーンと分散型計算ネットワーク技術の経験7年。2008年、中国海洋大学コンピューター科学専攻卒業、2010年、華東師範大学でバイオインフォマティクスの修士課程と博士課程に進学中退",
      text3: "2012年にJushang Intelligenceを設立し、世界初の中国語AI音声アシスタントであるSmart 360を開発。3年間でソフトウェアのダウンロードユーザー数が1700万人を超えた。",
      text4: "2014年に世界初のAIスマートスピーカー、Xiao Zhi Super Speakerを発表。",
      text5: "2017年5月にDeepBrain Chainを設立し、現在は数百のAI、クラウドゲーム、サイバーカフェの顧客にサービスを提供しています。",
      text6: "2020年にDeepLinkを設立し、DeepLink Protocolは分散型AIクラウドゲームプロトコルであり、DeepBrain Chainと協力して世界のサイバーカフェやAAAゲーム会社にサービスを提供しています。"
    },
    application: {
      title: '職位応募',
      desc: 'DeepBrainChain基金会では現在、シンガポール、中国、韓国、台湾、日本、インド、ベトナム、バングラデシュ、ナイジェリアなど、30人以上の研究開発およびマーケティングのフルタイムおよびパートタイムの従業員を擁しています。'
    },
    weekly: '最近の16号DBCバイウィークリーレポート',
    see: '詳細を見る',
    seemore: '続きを見る'
  },
  deployCloud: {
    title: '強力なGPUを借りるAPI',
    step1: {
      text1: 'オンチェーンAPIを使用してGPUを借りる',
      text2: 'APIノードのアドレス：xxxx、または独自のブロックチェーンAPI同期ノードをデプロイする。 デプロイメントのドキュメント：',
      text3: 'チェーン上のマシンを借りる',
      text4: '利用可能性を確認して借りる',
      text5: '更新',
    },
    step2: {
      text1: 'GPU整機を借りる',
      text2: '仮想マシンを作成する',
      text3: '仮想マシンを再起動する',
      text4: '利用可能性を確認して借りる',
      text5: '更新',
    },
    step3: {
      text1: 'GPUベアメタルマシンを借りる',
      text2: 'マシンを作成する',
      text3: 'マシンを再起動する',
      text4: 'シャットダウン',
      text5: 'DeepLinkデバイスIDと確認コードを取得する',
      text6: 'Ubuntuアカウントとパスワードを取得する',
    },
    server: 'ご質問がある場合は、お問い合わせください：'
  },
  cloudInternet: {
    title: 'クラウドインターネットカフェソリューションプロバイダになる方法',
    text: '現在、世界中の主要なインターネットカフェの分布国は韓国（6,000）、中国（40,000）、ブラジル（10,000）、インドネシア（20,000）、フィリピン（20,000）、ベトナム（15,000）、トルコ（5,000）などです。 さらに、インド、メキシコ、中東、南アメリカ、アフリカ、ヨーロッパなどの国々があります。 グローバルには15万以上のインターネットカフェがあります。 ただし、インターネットカフェ業界は20年の歴史を持ち、伝統的な産業になっています。 ペイバック期間は3〜4年に延び、さまざまなリスクに直面しています。 たとえば、COVID-19パンデミックにより多くのインターネットカフェが閉鎖されました。 したがって、インターネットカフェ業界は、ペイバック期間を短縮し、リスクを軽減し、運営効率を向上させる新しい解決策が必要です。 このようにして、クラウドインターネットカフェソリューションが誕生します。',
    cont2: {
      text1: '何が',
      text2: 'クラウドネットカフェソリューション？',
      text3: 'クラウドサイバーカフェソリューションは、超低遅延レンダリング技術を備えた新しいタイプのサイバーカフェサービスモデルです。クラウドサイバーカフェでは、ゲームやアプリケーションはローカルコンピュータに直接インストールされず、クラウドGPUサーバー上にホストされます。ユーザーは高性能なローカルハードウェアを必要とせず、クラウドサービスを通じてゲームにアクセスしてプレイします。',
      block1: {
        text1: 'ローカルハードウェア要件の削減',
        text2: 'ゲームやアプリケーションがクラウドGPUサーバー上で実行されるため、ローカルコンピュータには高性能なGPUや大容量のストレージは必要ありません。これにより、サイバーカフェのハードウェア投資と保守コストが削減されます。'
      },
      block2: {
        text1: '最新のゲームへの即時アクセス',
        text2: 'ユーザーはダウンロードやインストールを待たずに最新のゲームに即時アクセスできます。すべてのゲームの更新とメンテナンスはクラウドで行われ、ユーザーに便利で効率的な体験を提供します。'
      },
      block3: {
        text1: '高性能なゲーム体験',
        text2: 'ローカルモニターにはミニPC（約100ドル）が必要で、クラウドサイバーカフェでも高性能なゲーム体験を提供できます。これは、ゲームの計算がリモートの高性能GPUサーバー上で行われるためです。'
      },
      block4: {
        text1: 'スペースとエネルギーの節約',
        text2: '低構成のローカルコンピュータは、より少ないスペースを占有し、より少ない電力を消費し、サイバーカフェの運営コストを削減するのに役立ちます。'
      },
      block5: {
        text1: '管理とメンテナンスが容易',
        text2: 'サイバーカフェの管理者は、すべてのコンテンツがクラウドで中央管理されているため、ソフトウェアやゲームをより簡単に管理できます。これにより、ローカルのメンテナンス作業や潜在的な技術的な問題が減少します。'
      }
    },
    cont3: {
      text1: '韓国のGlowsteamクラウドサイバーカフェ',
      text2: 'クラウドサイバーカフェの実際の運用環境'
    },
    cont4: {
      text1: 'クラウドサイバーカフェソリューションは、サイバーカフェ投資家を助けることができます',
      text2: '純利益を3倍に増やし、ペイバック期間を40％短縮します',
      text3: '比較データのダウンロードをクリック'
    },
    cont5: {
      text1: 'クラウドサイバーカフェソリューションプロバイダーの収益はどのようにして得られますか？',
      text2: 'もし利益がサイバーカフェの機械ごとの収益の12％として計算された場合、合計1000のサイバーカフェに100,000台の機械がサービスされ、1日あたり平均10時間の使用時間があり、1台あたりの平均費用が0.8ドルである場合、12％は約0.1ドルに相当します。',
      text3: 'クラウドサイバーカフェソリューションプロバイダーの年間利益は',
      text4: '100,000 * 10 * 0.1 * 365 = 3,650万ドル',
      text5: 'クラウドサイバーカフェソリューションプロバイダーになる方法',
      text6: 'DeepBrainChainとDeepLink技術に基づいて、韓国のHYCONSが完全なクラウドサイバーカフェソリューションを開発しました。DeepBrainChainはGPUコンピューティングパワーを提供し、DeepLinkは超低遅延レンダリング技術を提供し、HYCONSは完全なソリューションを提供します。類推すると、DeepBrainChainがエンジンを提供し、DeepLinkが自動運転システムを提供し、HYCONSが完全な車のソリューションを提供します。さまざまな国のローカルソリューションプロバイダーは、ローカルのサイバーカフェにサービスを提供するためにローカルの支払いシステムをHYCONSに統合するだけでよく、ソリューションプロバイダーは独自のブランドを設定できます。 HYCONSは、ブランドを公開せずに純粋な技術サービスプロバイダーとしてのみ機能します。',
      text7: '詳細については、お問い合わせください：',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: 'クラウドサイバーカフェ関連資料のダウンロード',
      text1: 'HYCONS紹介',
      text2: 'クラウドサイバーカフェソリューション利益分析',
      text3: 'クラウドサイバーカフェデータセンターアーキテクチャダイアグラム',
      text4: 'クラウドサイバーカフェソリューションテストおよびインストールマニュアル',
      text5: 'DeepLinkプロトコル紹介'
    }
  },
  gameAgents: {
    title: 'クラウドゲーム一体機代理店になる方法',
    text: 'クラウドゲーム一体機とは何ですか？ 伝統的なゲーム機に比べて、クラウドゲーム機は高FPSのリフレッシュレートを持ち、よりスムーズなゲーム体験を実現します。 HKCグループのTIKERENクラウドゲーム一体機は、DeepBrainChain + DeepLinkテクノロジーに基づいて開発され、リモートGPUの高性能コンピューティングにリアルタイムで接続し、ゲームのレンダリングと計算を行います。',
    title1: 'TIKEREN一体機のプレゼンテーション',
    table: {
      th: {
        text1: '並べ替え',
        text2: 'アイテム',
        text3: '27インチ',
        text4: '31.5インチ'
      },
      td1: {
        text1: 'Mode Type',
        text2: 'MA27H13QC1',
        text3: 'MA27H13QC1'
      },
      td2: {
        text1: 'Color Category',
        text2: 'Black',
        text3: 'Black'
      },
      td3: {
        text1: 'Display Size',
        text2: '27インチ',
        text3: '31.5インチ'
      },
      td4: {
        text1: 'Aspect Ratio',
        text2: 'Wide (16:9)',
        text3: 'Wide (16:9)'
      },
      td5: {
        text1: 'Panel Type',
        text2: 'IPS',
        text3: 'VA'
      },
      td6: {
        text1: 'Panel Resolution',
        text2: 'QHD 2560*1440',
        text3: 'QHD 2560*1440'
      },
      td7: {
        text1: 'Panel Refresh Rate',
        text2: '180Hz (Max)',
        text3: '170Hz(Max)'
      },
      td8: {
        text1: 'Responese Time',
        text2: '1mS(MPRT).GTG 1ms',
        text3: '1ms(MPRT).GTG 1ms'
      },
      td9: {
        text1: 'Color Gamut',
        text2: 'DCIP3 90%',
        text3: 'DCIP3 90%'
      },
      td10: {
        text1: 'Mainboard Solution',
        text2: 'Intel® N100 Chipset',
        text3: 'Intel® N100 Chipset'
      },
      td11: {
        text1: 'CPU/Cooler',
        text2: 'Intel N100 3.4GHz',
        text3: 'Intel N100 3.4GHz'
      },
      td12: {
        text1: 'Memory',
        text2: 'DDR4 8GB(Option Max 32G)',
        text3: 'DDR4 8GB(Option Max 32G)'
      },
      td13: {
        text1: 'Hard Disk',
        text2: 'SSD 128G(Option Max 1T)',
        text3: 'SSD 128G(Option Max 1T)'
      },
      td14: {
        text1: 'OS',
        text2: 'Windows 10/11(0ption)',
        text3: 'Windows 10/11(0ption)'
      },
      td15: {
        text1: 'GPU',
        text2: 'Intel UHD Graphics',
        text3: 'Intel UHD Graphics'
      },
      td16: {
        text1: 'Rear Interface',
        text2: '1*DC Power Connector，1*RJ45(2.5G)，4* USB 3.0，1*Audio jacks',
        text3: '1*DC Power Connector，1*HDMIN，1*HDMI OUT，1*RJ45(2.5G)，4* USB 3.0,1*Audio iacks'
      },
      td17: {
        text1: 'Side Interface',
        text2: 'USB 2.0x2，Power Button x1',
        text3: 'USB 2.0x2，Power Button x1'
      },
      td18: {
        text1: 'Wi-Fi',
        text2: '2.4G~5GHz(Wi-Fi5),BT(4.2)',
        text3: '2.4G~5GHz(Wi-Fi5),BT(4.2)'
      },
      td19: {
        text1: 'Audio',
        text2: 'Built-in Speakers*2',
        text3: 'Built-in Speakers*2'
      },
      td20: {
        text1: 'Base/Stand',
        text2: 'HAS:VESA',
        text3: 'HAS:VESA'
      },
      td21: {
        text1: 'Pivot',
        text2: '上、下、左、右、90度回転、高さ調整',
        text3: '上、下、左、右、90度回転、高さ調整'
      },
      td22: {
        text1: 'Power',
        text2: '90W',
        text3: '90W'
      },
      td23: {
        text1: 'Game Screenlmprovement',
        text2: 'Free Sync, G-Sync',
        text3: 'Free Sync, G-Sync'
      }
    },
    text1: 'メールで連絡してください'
  },
  convertGame: {
    title: 'AAAゲームをクラウドゲームに変換する方法',
    text: 'DeepLinkプロトコルは、分散型の超低遅延クラウドゲームレンダリングプロトコルであり、DeepLinkプロトコルに基づいてクラウドゲーム関連製品を開発できる任意のアプリケーションで、クラウドコンピューティングパワーはDeepBrainChainによって提供されます。',
    text1: 'DeepLinkプロトコルの理解',
    step: {
      title1: 'ゲームをDeepLinkプロトコルに統合する',
      text1: "service{'@'}deeplink.cloudに連絡する",
      title2: 'DeepLinkは自動的にDeepBrainChain GPUサーバーを呼び出します',
      text2: 'ゲームをクラウドで実行する'
    }
  },
  applyForFunding: {
    title: '理事会の国庫資金を申請する方法',
    text1: '資金を申請する前に、理事会資金申請Telegramディスカッショングループに参加できます。',
    text2: 'Telegramディスカッショングループ',
    cont: {
      title: 'DBC Treasury 資金申請プロセス',
      text: '申請者が国庫から資金を要求する際には、提案金額の5％を予約する必要があります。国庫提案が承認されると、予約された資金は返金されます。国庫提案が拒否されると、予約された資金が罰則を受けます。提案を送信した後は、ユーザーは提案を取り消すことはできません。',
    },
    procedure: {
      text: '理事会国庫資金申請手順：',
      title1: '草案の準備',
      text1: '提案テンプレートに基づいて草案を準備し、提案テンプレートを参照してください',
      text2: '英語:',
      text3: '中国語:',
      title2: 'オフチェーンでの提案の議論',
      text4: '提案をチェーン上に提出する前に、提案者はチェーン外で理事会やコミュニティメンバーと提案を議論することができます。WeChat、Telegramグループなど、任意のフォーラムで、複数の意見を統合し、提案を継続的に改善します。現在、DBCはReddit（https://www.reddit.com/r/DBC_Council/）にDBC_Councilというサブレディットコミュニティを設立しており、中国本土ではVPNが必要です。このコミュニティには、提案の議論用のセクション「proposal_draft discussion」も設けられています。提案者はこのコミュニティに草案を投稿し、議論のためにフレア「proposal_draft discussion」を追加できます。',
      title3: 'チェーン上での提出',
      text3_1: 'DBCメインネットのgovernance-treasuryページ（https://www.dbcwallet.io/#/treasury）で提案をチェーン上に提出し、要求されたDBC金額を入力します。その後、システムは自動的にDBCの5%を担保としてステークします。',
      text3_2: '注意！提案が承認されると、担保は返金されます。提案が拒否されると、担保は返金されません！',
      text3_3: 'したがって、提案の準備を注意深く行い、比較的完全かつ実行可能な提案を提出してください。',
      text3_4: 'チェーン上での提出と同時に、提案者はRedditコミュニティDBC_Council（https://www.reddit.com/r/DBC_Council/）に提案内容を投稿し、フレア「proposal_submitted onchain」を追加する必要があります。チェーン上の提出後、提案番号とブロック高さが送信されます。提案投稿に【番号】を追加して、チェーン上とオフチェーンの対応を容易にします。',
      title4: '理事会の提案と投票',
      text4_1: '申請者が申請を完了すると、https://www.dbcwallet.io/#/treasuryで申請内容を確認できます。',
      text4_2: 'この時点で、理事会は申請に賛成または反対する動議を提案できます。申請を承認するための動議には、理事会の3/5の同意が必要です。申請を拒否するための動議には、理事会の1/2の同意が必要です。',
      text4_3: '注意！理事会は、議会での投票のために国庫（https://www.dbcwallet.io/#/treasury）で動議を提案する必要があります（https://www.dbcwallet.io/#/council/motions）。 ',
      title5: '投票完了',
      text5_1: '投票期間が終了した後、理事会は動議を手動で閉じる必要があります（https://www.dbcwallet.io/#/council/motions）。 ',
      text5_2: '投票後、承認された提案はDBC subredditのコミュニティ管理者によってフレア「proposal_approved」にアーカイブされ、拒否された提案はフレア「proposal_rejected」に分類されます。',
      title6: '資金の支出',
      text6_1: '資金は分割して支給され、成功した提案者は進捗状況を継続的に更新する必要があります。提案ごとの必要な資金を明記してください。提案の完了は、コミュニティメンバー（理事会メンバーではない）が投票で監督します。',
      text6_2: '特記事項：資金の受け取りアドレスは取引所アドレスではなく、プライベートキーを所有しているアドレスである必要があります。できるだけDBCメインネットで生成されたアドレスであることが望ましいです。そうでないと、承認後に支給される資金を受け取ることはできません。',
      text6_3: '注意：承認された資金申請の支給は支出サイクル終了時に行われます。支出サイクルはhttps://www.dbcwallet.io/#/treasuryで確認できます（右上に支出サイクルのカウントダウンが表示されます）。'
    },
  },
  createwallet: {
    title: 'DBCウォレット',
    text: 'DBCウォレットはDBC財団によって完全機能を備えたウォレットとして維持されており、このウォレットを使用してDBCおよびDLCのウォレットアドレスを作成し、POS投票に参加したり、理事会選挙への投票に参加したり、投票などに参加できます。',
    text1: 'ウェブサイトを訪問する',
    cont2: {
      title: 'ウォレットアドレスの作成方法',
      text1: 'ユーザーの資産セキュリティを保護するために、ウォレットの作成はプラグイン形式で行われます',
      step: {
        title1: 'Google Chromeブラウザをインストールする',
        title2: 'Polkadotプラグインを検索する',
        text2: 'プラグインをインストールする',
        title3: 'シードフレーズを保存する',
        text3: 'Polkadotプラグインを開き、ウォレットを作成し、すべてのチェーンをサポートするように選択し、シードフレーズを保存します',
        title4: 'www.dbcwallet.ioをリフレッシュする',
        text4: '私のアカウントページには、すでに作成されたウォレットが表示されます'
      }
    },
    title1: 'ウォレット作成ビデオ'
  },
  polkaWallet: {
    title: 'PolkaWallet ウォレット',
    text: 'Google PlayやApp StoreでPolkaWalletを検索するか、公式ウェブサイト：PolkaWallet.ioを訪れてダウンロードしてください。',
    text1: 'PolkaWallet.ioからダウンロード',
    // step: {
    //   title: '创建流程',
    //   text: '为了保护用户持币安全，采用插件的形式创建钱包',
    //   title1: '安装Google Chrome浏览器',
    //   title2: '搜索Polkadot插件',
    //   text2: '安装插件',
    //   title3: '保存种子',
    //   text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
    //   title4: '刷新www.dbcwallet.io',
    //   text4: '我的账户页面会出现已经创建的钱包'
    // },
    step: {
      title: '作成プロセス',
      text1: '1. ブラウザでpolkawallet.ioを開きます。',
      text2: '2. Polkadot公式ウェブサイトにアクセスしてAPPウォレットをダウンロードします。',
      text3: '3. ダウンロード後、DBCメインネットワークのウォレットの「新規作成」または「インポート」を選択します。',
      text4: '4. Substrateアカウントを選択します。',
      text5: '5. 新しいウォレットの場合は、鍵を適切にバックアップしてください。',
      text6: '6. 名前とパスワードを設定し、メインインターフェースに入ることができます。',
      text7: '7. メインインターフェースで左にスワイプしてDBCを選択します。',
      text8: '8. アイコンをクリックしてメインネットDBCを送受信します。',
      text9: '9. 受信インターフェース。',
      text10: '10. 送信および転送インターフェース。メインネットワークウォレットで使用されるガスはDBCですので、送金時には少量のDBCを予約してください。'
    }
  },
  mathWallet: {
    title: 'MathWallet ウォレット',
    text: 'Google PlayやApp StoreでMathWalletを検索するか、公式ウェブサイト：https://mathwallet.org/ にアクセスしてダウンロードしてください。',
    text1: 'MathWalletをダウンロード',
    step: {
      title: '作成プロセス',
      text1: '1. ブラウザでmathwallet.orgを開きます。',
      text2: '2. 公式ウェブサイトに入ったら、ダウンロードパスを選択します。',
      text3: '3. Androidの場合、インストールパッケージを直接ダウンロードするか、Google Playストアに移動してダウンロードできます。',
      text4: '4. ダウンロードが完了したら、ウォレットを開き、手動でDBCメインネットワークを選択します。',
      text5: '5. DBCメインネットワークのウォレットは、最後から4ページ目にあります。',
      text6: '6. 作成またはインポートできます。',
      text7: '7. パスワードの作成には大文字と小文字の両方が必要です。',
      text8: '8. ニーモニックフレーズをバックアップしてください。',
      text9: '9. DBCアイコンが表示されたら、クリックしてアセットを受信および送信できます。',
      text10: '10. 送信インターフェース。',
      text11: '11. より多くのウォレットを作成するには左にスワイプします。',
      text12: '12. 右下隅の設定では、言語を選択できます。',
      text13: '13. メインインターフェイスの中央下部には、メインネットワークブラウザが表示されます。'
    }
  },
  ERC20Wallet: {
    title: 'ERC20 Wallet',
    text1: 'MetaMask',
    text2: 'Imtoken',
    text3: 'TrustWallet',
    text4: 'DBC契約アドレスを検索：',
    text5: '精度：',
  },
  ImToken: {
    title: 'ImTokenウォレット',
    text: '公式ウェブサイトを訪問してダウンロードしてください：token.im',
    text1: 'token.imをダウンロードする',
    step: {
      title: '作成手順',
      text1: '1. ブラウザでtoken.imを入力してください',
      text2: '2. 入力後、「今すぐダウンロード」をクリックしてください',
      text3: '3. 直接インストールするか、Google Playストアからインストールするかを選択してください',
      text4: '4. ダウンロード後、アプリ内でウォレットを作成してください',
      text5: '5. 作成後、キーをすぐにバックアップして安全に保管することをお勧めします',
      text6: '6. ウォレットのメインページで、「+」記号をクリックしてトークンを追加してください',
      text7: '7. 検索バーにDBCの契約アドレスを入力してください：0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. 成功裏に追加した後、メインページに戻ってDBCアイコンを表示できます',
      text9: '9. アイコンをクリックしてDBCの受信および送信アドレスを表示できます',
      text10: '10. 他の人に送信する場合は、その人のERC20アドレスを使用することを確認してください',
    }
  },
  trustWallet: {
    title: 'TrustWalletウォレット',
    text: 'Google PlayやApp StoreでTrustWalletを検索するか、公式ウェブサイト：https://trustwallet.com/にアクセスしてダウンロードしてください',
    text1: 'TrustWalletをダウンロード',
    step: {
      title: '作成手順',
      text1: '1. ブラウザでtrustwallet.comを入力してください',
      text2: '2. 入力後、3つのダウンロードオプションが表示されます',
      text3: '3. ダウンロード後、新しいウォレットを作成してください',
      text4: '4. 新しいウォレットをすぐにバックアップしてください',
      text5: '5. バックアップが完了したら、メインインタフェースに入力します',
      text6: '6. メインインタフェースの右上に重なっている小さな「+」アイコンをクリックし、その後、現在のページの右上にある「+」アイコンをクリックします',
      text7: '7. トークンをインポートする画面で、DBCの契約アドレスを貼り付けます：0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. インポートが成功したら、DBCアイコンが表示されます',
      text9: '9. 「受信」をクリックして現在のDBCウォレットアドレスを取得します',
      text10: '10. 「送信」をクリックして送信インタフェースに入り、トランザクションを完了するためにERC20チェーン用に十分なETHが予約されていることを確認してください',
    }
  },
  metaMask: {
    title: 'MetaMaskウォレット',
    text: 'Google PlayでMetaMaskを検索するか、公式ウェブサイト：https://metamask.io/を訪問してダウンロードしてください',
    text1: 'MetaMaskをダウンロード',
    step: {
      title: '作成プロセス',
      text1: '1. ブラウザでmetamask.ioを開きます',
      text2: '2. Google Playストアからインストールします',
      text3: '3. ダウンロード後、ウォレットをインポートまたは作成して、メインインターフェイスに入ります',
      text4: '4. インターフェイスの下部にある「トークンをインポート」をクリックして、トークンを追加するページに移動します',
      text5: '5. 「カスタムトークン」を選択し、DBCの契約アドレスを入力します：0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text6: '6. インポートに成功すると、メインインターフェイスにDBCアイコンが表示されます',
      text7: '7. クリックして入力し、DBCを受け取るオプションを表示および送信できます',
      text8: '8. 送信には、ガスのためにウォレットにETHが必要です'
    }
  },
  footer: {
    logo: 'ロゴのダウンロード',
    server: 'サービスメール'
  },
}